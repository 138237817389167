/* ========================================
// Silk Halfway
// ===================================== */

.halfway-section {

  .cssanimations & {

    @mixin media $gamagori {}

  }

}

.element-halfway {

  .cssanimations & {
    
    @mixin media $gamagori {}

  }

}
