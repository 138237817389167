.panel {
    background: $s5;
    padding: em(25) em(30);
    border-bottom: 5px solid $m3;
    width: 100%;

    @mixin media $wooser {
        padding:  2em 1.5em 1.25em 2.125em;
    }
}

.featured-degree {
    height: 500px;
    .panel {
        height: 500px;
    }
}

.home-event-carousel {
    &.featured-degrees-carousel { 
        .owl-prev, 
        .owl-next {
            background: #333;
            padding: 10px;
            top: 200px;
            color: #fff;
        }
        .owl-item {
            vertical-align: top;
        }

    }
}


.panel-title a {
    color: $m3;
    text-decoration: none;
}

.panel-title a:hover {
    text-decoration: underline;
}

.panel--bordered {
    border: 1px solid $s4;
    border-bottom: 5px solid $m3;
}

.panel--featured-degree {

    .degree-title {
        margin-top: 0;
        padding-bottom: 0
    }

    .degree-description {
        margin-bottom: 1.25em;
    }

    .degree-type {
        font-size: em(16);
        font-weight: 600;
        a {
            color: #999;
            &:hover {
                color: #333;
            }
        }
    }

    p {
        margin-bottom: 0.5em;
    }

}

.panel--student {
    .panel--student-col {
        padding: em(20);
        @mixin media $wooser {
            padding: em(40);
            padding-left: 0;
        }
    }
    figcaption {
        color: #8A9298;
    }
    .student-panel--media {
        padding: 0;
    }
}

.student-panel--content-quote {
    text-align: center;
    .block-lead-in {
        font-size: em(24);
        font-style: italic;
    }
}

.gray-bg.panel--student {
    border-bottom: 4px solid $m3;
    .panel--student-col {
        padding: em(20);
        @mixin media $wooser {
            padding: em(40);
        }
    }
}

.panel--event {
    position: relative;
    padding-top:em(105);
    border-bottom: none;
    border-top: 5px solid $m3;
    margin-bottom: em(50);

    .panel-title {
        margin-bottom: em(7)
    }

    .event-date {
        color: $s2;
        font-weight: bold;
    }

    &:before {
        content: "Featured Event";
        display: inline-block;
        font-size: em(18);
        font-weight: bold;
        color: $s6;
        text-transform: uppercase;
        background: $m3;
         padding: em(15) em(30);
        position: absolute;
        top: 0;
        left: 0;

     }



    @mixin media $wooser {
        &:before {
            font-size: em(22);
            padding: em(15) em(45);
        }
        .event-date {
            font-size: em(22);
        }
    }
}

.all-degrees-list a.disabled:after {
    color: #ccc;
}


.all-degrees-list {
    margin-top: em(40);
    margin-bottom: em(40);
    .section-title {
      display: inline-block;
    }
    .degree-filters {
      text-align: right;
      position: relative;
      top: 25px;
      margin-bottom: em(20);
      @mixin media $large {
        display: inline-block;
        float: right;
        margin-bottom: 0;
      } 
      &:after {
        content: "\f078";
        position: absolute;
        right: 10px;
        top: 10px;
        color: #fff;
        @mixin fa;
        font-size: 14px;
        transition: 0.3s;
        @mixin media $large {
            display: none;
        }
      }
      &.open {
        &:after {
            transform: rotate(180deg);
        }
        span {
            display: block;
        }
      }
      span {
        padding: em(5) em(10);
        background-color: $s2;
        color: #fff;
        width: 100%;
        cursor: pointer;
        display: none;
        text-align: left;
        transition: 0.2s;
        @mixin media $large {
            width: auto;
            display: inline;
        }
        &:hover {
            background-color: $m3;
        }
        &.active {
          display: block;
          @mixin media $large {
            display: inline;
            background-color: $m3;
            cursor: initial;
          }
        }
      }
    }
    .all-degrees {
      margin-top: em(40);
    }
    .clear {
        @mixin media $large {
            margin-left: -40px;
            margin-right: -40px;
        }
    }
    .col-wooser-4 {
        @mixin media $large {
            padding-left: 40px;
            padding-right: 40px;
        }
    }
    .col-wooser-4:not(:last-of-type){
        @mixin media $large {
            border-right: 1px solid #ccc;
        }
    }
    .list-title {
        text-transform: uppercase;
        @mixin h4;
        margin-top: 0;
    }
    ul {
        padding-bottom: em(20);
        li {
            padding-bottom: 14px;
            line-height: 1.1;
        }
    }
    a {
        color: #000;
        line-height: 1.1;
        text-decoration: none;
        &:hover {
            text-decoration: none;
            color: $m3;
        }
        &.disabled {
          color: #ccc;
          pointer-events: none;
          display: inline-block;
          &:hover {
            text-decoration: none;
          }
          &:after {
            color: #ccc;
          }
        }
        &:after {  
            font: normal normal normal 14px/1 FontAwesome;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\f054";
            color: $m3;
            padding-left: 5px;
            font-size: 12px;
            display: inline-block;
        }
    } 
}