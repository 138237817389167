#search-form {

    .form-wrapper {
        @mixin media $medium {
            width: 70%;
        }
    }

    .form-text {
        background-color: #f1f1f1;
        background-image: url(../icons/search-blk.svg);
        background-repeat: no-repeat;
        background-position: 98% 50%;
    }

    .form-submit {
        visibility: hidden;
    }

}

/* Google custom search */
body #google-cse-results {
  @mixin compartment;
  font-size: 18px !important;



  /* Reset google hardcoded text size */
  .gsc-control-cse, .gsc-result, .gsc-control-cse .gsc-table-result {
    font-size: 18px !important;
    font-family: "Source Sans Pro", sans-serif !important;
  }

  /* Hide some empty boxes */
  .gcsc-branding, .gsc-resultsHeader {
    display: none;
  }

  /* Pagination */
  .gsc-results .gsc-cursor-box .gsc-cursor-current-page, .gsc-results .gsc-cursor-box .gsc-cursor-page {
    color: #E31933 !important;
    font-family: "Source Sans Pro", sans-serif !important;
    a {
      color: #E31933 !important;
    }
  }

  /* Results */
  .gsc-result {
    border-bottom: 1px solid #DCDDDE;
    padding-top: 40px;
    font-family: "Source Sans Pro", sans-serif !important;

    .gsc-thumbnail-inside, .gsc-url-top, .gsc-table-cell-thumbnail {
      padding-left: 0px;
      padding-right: 0px;
    }


    .gs-visibleUrl {
      color: #E31933;
    }

    .gs-title {
      text-decoration: none !important;
      overflow:auto;
      height: 2.1em;
      font-family: "Source Sans Pro", sans-serif !important;

      a {
        color: #434A4F !important;
        text-decoration: none !important;
        @mixin h2;
          b {
            font-size: 1em;
            text-decoration: none !important;
            color: #434A4F !important;
          }
      }
    }
  }
}
