@define-mixin compartment $max-width: $boundary {
  max-width: rem($max-width);
  margin-right: auto;
  margin-left: auto;
  padding-left: em(30);
  padding-right: em(30);
}

.compartment {
  @mixin compartment;

  &--nested {
    max-width: rem(980);
  }

  &--small {
  	max-width: 768px;
		margin: 0 auto;
		padding-top: em(30);
		padding-bottom: em(20);
		padding-left: em(30);
		padding-right: em(30);
  }

}
