.news-collage {
	.news-col-lg {
		width: 100%;
		@mixin media $large {
			width: 40%;
			float: left;
		}
	}
	.news-col-sm {
		width: 100%;
		@mixin media $large {
			width: 30%;
			float: left;
		}
	}
	.cell-tall {
		height: em(360);
	}
	.cell-med {
		height: em(240);
	}
	.cell-sm {
		height: em(200);
	}
	.news-cell {
		@media (max-width: $large){
			height: auto;
		}
	}
	.news-cell,
	.event-cell {
		padding: em(30);
		position: relative;
		@mixin media $large {
			padding: em(20);
		}
	}
	.news-cat {
		color: $m2;
		font-size: em(14);
		font-weight: bold;
		text-transform: uppercase;
	}
	.news-title-lg {
		margin: 0;
		color: #000;
		&:hover {
			text-decoration: underline;
		}
	}
	.news-title-sm {
		margin: 0;
		color: #000;
		font-size: em(20);
		&:hover {
			text-decoration: underline;
		}
	}
	.cal-date {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: em(20) em(30);
		background: $m2;
		font-weight: 700;
		color: #000;
		.input-symbol {
			margin-right: 15px;
			position: relative;
			top: 3px;
			color: #8F780B;
		}
	}
	a {
		.news-title-sm {
			margin: 0;
			color: #000;
			@mixin h4
		}
	}
	.event-cell:hover {
		.news-title-sm {
			text-decoration: underline;
		}
	}
	p {
		font-size: em(16);
	}
	.more-info-nav {
		padding-top: em(20);
		padding-bottom: em(20);
		@mixin media $large {
			padding: 0;
		}
	}
	.more-info-nav--title {
		padding: em(10) em(20);
		text-align: center;
		text-transform: uppercase;
		font-size: em(16);
		background: $m3;
		color: #fff;
		margin: 0;
		transition: 0.2s;
		width: 90%;
		margin-left: 5%;
		margin-top: 10px;
		margin-bottom: 10px;
		@mixin media $large {
			color: #000;
			padding: em(25) em(20);
			width: 100%;
			background: $m1;
			margin: 0;
			font-size: em(22);
		}
		&:hover {
			.fa-chevron-left {
				animation: left-bounce 1.5s infinite;
			}
			.fa-chevron-right {
				animation: right-bounce 1.5s infinite;
			}
		}
		&.dark {
			@mixin media $large {
				background: $m2;
			}
		}
		.fa {
			display: none;
			@mixin media $large {
				display: inline-block;
			}
		}
		.fa-chevron-left {
			transform: translate3d(-8px, -1px , 0);
			font-size: 0.8em;
		}
		.fa-chevron-right {
			transform: translate3d(13px, -1px , 0);
			font-size: 0.8em;
		}
	}
	.input-symbol svg {
		color: #8F780B;
	}
}

.news-collage .gray-bg .news-cat {
	color: #8A9298;
}

.news-collage .yellow-bg .news-cat {
	color: #8F780B;
}
.news-collage .yellow-bg .news-title-sm,
.news-collage .yellow-bg .cal-date {
	color: #121b21;
}

.bg-img {
	position: relative;
	overflow: hidden;
	.bg-quote {
		position: absolute;
		bottom: 0;
		color: #fff;
		font-weight: 400;
		font-size: em(20);
		width: 100%;
		height: 60%;
		transform: translate3d(0, 40%, 0);
		line-height: 1.3em;
		transition: 0.3s;
		font-style: italic;
		padding: em(20);
		padding-top: em(40);
		padding-left: em(40);
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 60%);
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 60%);
		&:before {
			content: "\201C";
			font-size: 60px;
			position: absolute;
			font-weight: 700;
			left: 12px;
		}
		span {
			opacity: 0;
			transition: 0.3s;
		}
	}
	&:hover {
		.bg-quote {                                  
			transform: translate3d(0, 0, 0);
			span {
				opacity: 1;
			}
		}
	}
}
.news--grid {
	.bg-quote {
		transform: translate3d(0, 40%, 0);
		height: 70%;
	}
}

@keyframes left-bounce {
  0%, 100% {
    transform: translate3d(-8px, -1px , 0);
  }
  50% {
    transform: translate3d(-13px, -1px , 0);
  }
}

@keyframes right-bounce {
  0%, 100% {
    transform: translate3d(13px, -1px , 0);
  }
  50% {
    transform: translate3d(18px, -1px , 0);
  }
}
