.gray-bg {
	background-color: #f1f1f1;
}
.yellow-bg {
	background-color: $m1;
}

.yellow {
	color: $m1;
}

.white {
	color: #fff;
}

.page-subtitle {
	font-size: em(22);
}

.hidden-mobile {
	display: none;
	@mixin media $large {
		display: block;
	}
}

.center-text {
	text-align: center;
}

.grid--flex {
    display: flex;
}

h1,h2,h3,h4,h5,h6 {
	margin-bottom: 10px;
}

.pb30 {
	margin-bottom: em(30);
}

.section-title {
	display: inline-block;
	font-size: em($h3Size);
  line-height: nu($h3Line, $h3Size);
  margin-top: em(20);
  @mixin media $medium {
    font-size: em($h2Size);
    line-height: nu($h2Line, $h2Size);
    margin-bottom: 15px;
  }
  &.light {
  	font-weight: 400;
  }
}

.red-btm {
	border-bottom: 4px solid $m3;
}

.relative {
	position: relative;
}

.italic {
	font-style: italic;
}

.updated-text {
	color: #ccc;
  font-size: em(14);
  display: block;
  font-style: italic;
}

.top-link {
	position: fixed;
	bottom: em(100);
	right: em(50);
  z-index: 100;
  color: #000;
  background: $m1;
  padding: 7px;
  width: 50px;
  height: 50px;
  font-size: em(14);
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 100%;
  text-align: center;
  cursor: pointer;
  span {
  	position: relative;
  	top: -5px;
  }
}

.more-link a {
	color: $m3;
	text-transform: uppercase;
	font-size: em(15);
	font-weight: 600;
	position: relative;
	padding: 10px 0 8px;
	display: inline-block;
	text-shadow: none;
	margin-bottom: em(20);
	text-decoration: none;
	&:after {
		content: '';
		position: absolute;
		top: 100%;
		left: 0;
		background-color: $m3;
		height: 3px;
		width: 100%;
		transition: top 0.2s, opacity 0.3s;
		transform: translateZ(0);
		opacity: 0;
	}
	&:before {
		content: '';
		position: absolute;
		top: 100%;
		left: 0;
		background-color: $m3;
		height: 3px;
		width: 100%;
		transition: top 0.2s, opacity 0.3s;
		transform: translateZ(0);
	}
	&:hover:after {
		top: 0%;
		opacity: 1;
	}
	&:focus:after {
		top: 0%;
		opacity: 1;
	}
}

.more-link--yellow  {
	a {
		color: $m1;
		&:after {
			background-color: $m1;
		}
		&:before {
			background-color: $m1;
		}
	}
}
