/* ========================================
// Buttons
// ===================================== */

[class*='button'] {
  display: inline-block;
  vertical-align: top;
  border: 0;
  margin: em(5) em(10) em(5) 0;
  padding: em(10) em(20);
  cursor: pointer;
  text-align: center;
  background: $m1;
  color: #fff;
  outline: 0;
  transition:
  background .2s,
  box-shadow .2s,
  color .2s,
  transform .2s;
}


/* Additional Buttons
// ------------------------------------- */

.button {
  transition: all $globalTransitionSpeed ease-in-out;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: em(40);
  text-decoration: none;
  &--hollow {
    border: 1px solid;
    background: none;
    color: $m3;
    font-size: em(14);
    font-weight: 700;
    padding: 1em 2em;
    text-transform: uppercase;


    &:hover {
      color: #fff ;
      background: $m3;
      border-color: $m3;
     }
  }

  &--hollow-yellow {
    border: 1px solid;
    background: none;
    color: $m1;
    font-size: em(14);
    font-weight: 700;
    padding: 1em 2em;


    &:hover {
      color: #000;
      background: $m1;
      border-color: $m1;
     }
  }

  &--hollow-red {
    border: 1px solid;
    background: none;
    color: $m3;
    font-size: em(14);
    font-weight: 700;
    padding: 1em 2em;


    &:hover {
      color: #fff;
      background: $m3;
      border-color: $m3;
     }
  }

  &--yellow {
    background-color: $m1;
    color: #000;
    &:hover {
      background-color: #DEB807;
    }
  }

  &--cta {
    color: $s1;
    background: $m1;
    padding: 0.5em 1.5em;
    font-weight: 700;
    border: 2px solid $m1;
    width: 100%;
    margin-bottom: em(10);
    @mixin media $small {
      width: auto;
    }
    &:hover {
      background: none;
      color: $m1;
     }
   }
}


/* ========================================
// Site Logo/Link
// ===================================== */

/* If the site logo is text based */

.site__link {
  display: block;
  text-decoration: none;
}

/* If the site logo is image based */
/* Set the max-width to the logo's size */
/* overflow: hidden and white-space: nowrap prevent line breaks */

.site__logo {
  display: inline-block;
  overflow: hidden;
  height: 4.75em;
  max-width: em(230);
  margin-left: em(10);
  width: 100%;
  white-space: nowrap;
  background: url('../images/logo.svg') no-repeat 0 50%;
  background-size: contain;
  color: transparent;
  margin-top: em(5);
  @mixin media $silkDrawerDisappears {
    max-width: em(260);
    margin-left: 0;
   }
   @mixin media '1034px' {
    max-width: em(300);
   }
  &:hover {
    color: transparent;
  }

}
