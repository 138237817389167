/* ========================================
// Address
// ===================================== */

.vcard {

  a {
    display: block;
  }

}

  .org {}

  .adr {}

  .tel {}

  .email {}
