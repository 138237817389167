/* ========================================
// Index
// ===================================== */

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-family: $sansSerif;
  -webkit-font-smoothing: antialiased;
  background: none;
  color: $bodyColor;
}

  body {
    font-size: rem($bodySize);
    line-height: nu($bodyLine, $bodySize);
  }
