@define-mixin list-reset {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  font-size: rem($bodySize);
  line-height: nu($bodyLine, $bodySize);
  list-style: none;
}

.list-reset {
  @mixin list-reset;
}
