.card {
    background: $s5;
    .read-more {}
}

.card-image {
    background-size: cover;
    background-position: center center;
    height: em(340);

    @mixin media $large {
        height: em(430)
    }

    img {
        display: none;
    }
}

.card-content {
    padding: em(40) em(20);

    @mixin media $large {
        padding: em(40);
    }
/*
    p {
        font-size: em(18);
    }*/

    .read-more {
        font-size: em(16);
        color: $m3;
        border: none;
    }
}

.card-title {
    margin-bottom: 0.2em;
    margin-top: 0.5em;
    color: #000;
}