@define-mixin cover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cover {
  @mixin cover;
}
