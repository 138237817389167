/* ========================================
// Drawer
// ===================================== */

@define-mixin drawer $transform-intensity: 20% {
  position: absolute;
  min-height: 100%;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transform: translate(0, 0);
  transform: translate3d(0, 0, 0);
}

@define-mixin visible-drawer {
  pointer-events: auto;
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
  transform: translate3d(0, 0, 0);
}

@define-mixin reset-drawer {
  @mixin visible-drawer;
  position: static;
  overflow: inherit;
  max-width: 100%;
  background: none;
  transform: none;
  float: right;
  width: auto;
  overflow: visible;
  padding-bottom: 0;
}

.drawer {
  @mixin drawer;
  top: 100%;
  right: 0;
  z-index: 200;
  overflow: hidden;
  overflow-y: scroll;
  max-width: em(500);
  background: $s1;
  transition:
    opacity .3s,
    visibility .3s;
  border-top: 1px #292F35 solid;


  @mixin media $silkDrawerDisappears {
    @mixin reset-drawer;
  }

  .visible-drawer & {
    @mixin visible-drawer;
  }


}


/* ========================================
// Drawer Trigger
// ===================================== */

.drawer__trigger {
  position: absolute;
  top: 50%;
  right: 0;
  height: em(60);
  width: em(60);
  border: 0;
  padding: 0;
  outline: 0;
  cursor: pointer;
  background: none;
  transform: translate(0, -50%);
  transition:
    background .3s,
    transform .3s;

  @mixin media $silkDrawerDisappears {
    display: none;
  }

  &:before,
  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    height: em(2);
    width: em(20);
    content: '';
    background: currentColor;
    color: $s6;
    transform: translate(-50%, -50%);
    transition:
      background .3s,
      box-shadow .3s,
      margin .3s,
      transform .3s;
  }

  &:before {
    margin-top: em(-6);

    .visible-drawer & {
      margin-top: 0;
      transform:
        translate(-50%, -50%)
        rotate(-45deg);
    }

  }

  &:after {
    box-shadow: 0 em(6) 0 0 currentColor;

    .visible-drawer & {
      box-shadow: 0 0 0 0 transparent;
      transform:
        translate(-50%, -50%)
        rotate(45deg);
    }

  }


}
