@define-mixin media $width {

  @media (min-width: $width) {
    @mixin-content;
  }

}

@define-mixin large-media $width, $height {

  @media (min-width: $width) and (min-height: $height) {
    @mixin-content;
  }

}
