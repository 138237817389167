/* ========================================
// Swift Slide
// ===================================== */

.swift-slide {
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;

  li {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition:
      opacity .4s,
      visibility .4s,
      transform .4s;

    &.active {
      position: relative;
      pointer-events: auto;
      opacity: 1;
      visibility: visible;
    }

  }

}


/* ========================================
// Swift Controls
// ===================================== */

.swift-controls {
  user-select: none;

  button {
    border: 0;
    outline: 0;
    background: none;

    span {
      clip: rect(1px, 1px, 1px, 1px);
      position: absolute !important;
      height: 1px;
      width: 1px;
      overflow: hidden;
    }

    /* Previous Button */
    &:first-child {}

    /* Next Button */
    &:last-child {}

    &:hover {}

  }

}


/* ========================================
// Swift Pager
// ===================================== */

.swift-pager {
  user-select: none;

  span {
    display: inline-block;
    cursor: pointer;

    &:hover {}

    &.active {}

  }

}
