/* ========================================
// Main Navigation
// ===================================== */

.nav-main {

  @mixin media $silkDrawerDisappears {
    margin-top: em(10);
  }
   a {
     color: $s6;
     font-weight: bold;
     font-size: em(15);
     padding: em(20) em(20);
     margin-right: em(10);
     @mixin media $yatterman {
        font-size: em(17);
        margin-right: 12px;
     }

     @mixin media $silkDrawerDisappears {
       padding: 0;
      }
   }

  > ul {

    > li {

      @mixin media $silkDrawerDisappears {
        position: relative;
        display: inline-block;

        &.active a {
          border-bottom: 2px solid $m3;
          color: #fff;
        }
      }


      &:hover {

        > ul {

          @mixin media $silkDrawerDisappears {
            display: block;
          }

        }

      }


      ul {
        background: $s1;

        @mixin media $silkDrawerDisappears {
          width: em(220);
          background: $s1;
        }

        a {
          padding: em(10);
          padding-left: em(100);
          font-size: em(16);
          font-weight: 600;
          color: #fff;
          line-height: 20px;

          @mixin media $silkDrawerDisappears {
            padding-left: em(10);
            color: #fff;

            &:hover {
              color: $m1;
            }
          }

        }

      }

    }

  }

} 

/*.nav-main > ul > li > a:hover { 
  color: $m3; 
}*/

/* Ovveride some drupal menu stuff */
/*.nav-main > ul li a:hover:after  {
  transform: translateY(-3px);
  opacity: 1;
}
.nav-main > ul li a:after {
  content: '';
  width: calc(100% - 10px);
  height: 2px;
  background-color: $m3;
  position: absolute;
  bottom: -5px;
  left: 0;
  opacity: 0;
  transition: 0.2s;
}*/
.nav-main > ul li a.active {
  color: #fff; 
}
@mixin media $silkDrawerDisappears {
  .nav-main > ul li a.active {
    border-bottom: 2px solid $m3;
    color: #fff; 
  }
  .nav.nav-main > ul li.last ul {
    left: -117px;
}
.nav.nav-main > ul li:nth-last-of-type(2) ul {
    left: -61px;
}
}
ul li.expanded {
  list-style-type: none !important;
  list-style-image: none !important;
}

.nav-main a:hover {
  color: $m1;
}

.nav-main>ul li ul li a:after {
  display: none;
}
.nav-main>ul li ul li a.active {
  border-bottom: none;
}


/*.nav-main>ul li a:hover {
  color: $m3; 
}*/
 
.nav-utility li.expanded ul {
    display: none;
}

.silk-nav__controls a {
  text-decoration: none;
}


@media (max-width: $silkDrawerDisappears){
  .nav-main>ul>li ul a {
    padding-top: 1.1111111111111112em;
    padding-bottom: 1.1111111111111112em;
    padding-right: 4em;
  }
  .silk-nav__trigger--advance {
    margin-top: -50px;
}

.silk-nav__trigger--home svg {
  margin-top: -4px;
  margin-right: 6px;
}

.silk-nav__trigger--reverse {
  padding: 4px 15px;
}
}

