/*.hero--full {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url(../images/bg1.jpg);
	-webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-size: 250px;
  font-weight: bold;
  text-align: center;
  &:after {
  	content: '';
  	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100%;
  	background-color: rgba(255,255,255,0.6);
  	z-index: -1;
  }
  &:before {
  	content: '';
  	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100%;
  	background-image: inherit;
  	background-repeat: no-repeat;
  	background-size: cover;
  	z-index: -2;
  }
}*/

#loader {
	display: none;
}