/* ========================================
// Anchors
// ===================================== */

a {
  text-decoration: underline;
  color: $m3;
  transition:
    background .2s,
    color .2s;

  &:active {}

  &:hover {
    color: #000;
  }

}

li {
  a {
    color: $m3;
  }
}

/* Anchor Styles */

.read-more {
  border-bottom-style: solid;
  border-bottom-width: 2px;
  color: $s1;
  display: inline-block;
  border-bottom-color: $s1;
  font-weight: bold;
  text-transform: uppercase;
  transition: all $globalTransitionSpeed ease-in-out;

  &--normal {
    font-size: em(14);
    border: none;
   }

  &--red {
    color: $m3;
    border-bottom-color: $m3;
    &:hover {
      color: rgba(227,25,51, 0.8);
      border-bottom-color: rgba(227,25,51, 0.8);
     }
   }

  &--yellow {
    color: $m1;
    border-bottom-color: $m1;

    &:hover {
      color: $m2;
      border-bottom-color: $m2;
     }

   }
}




/* ========================================
// Blockquote / Cite
// ===================================== */

blockquote {
  margin: 0 0 em($push);
  font-size: em(22);
  font-style: italic;

  &:before {
    margin-right: em(5);
    content: '\201C';
  }

  &:after {
    content: '\201D';
  }

}

cite {
  @mixin h6;
  font-style: normal;

  &:before {
    content: '';
  }

}


/* ========================================
// Lists
// ===================================== */

ul,
ol {
  margin-top: 0;
  margin-bottom: em($push);
}

  li {}


/* ========================================
// Paragraphs
// ===================================== */

p {
  margin-top: 0;
  margin-bottom: em($push);
  a {
    color: $m3;
    text-decoration: underline;
    &:hover {
      color: #000;
    }
  }
}


/* ========================================
// Tables
// ===================================== */

table {
  width: 100%;
  border-spacing: 0;
  margin-bottom: em($push);

  th,
  td {
    padding: rem(10) rem($gutter);
  }

  th {
    @mixin h4;
    font-weight: normal;
    text-align: left;
    background: rgba(30, 0, 0, .075);
  }

  td {}

}

  /* Every td in every odd row */
  table tr:nth-of-type(even) td {
    background: rgba(30, 0, 0, .025);
  }


/* ========================================
// Lead In
// ===================================== */

.lead-in {
  font-size: em(22);
  font-style: italic;
  font-weight: 400;
  margin-bottom: em(20);
}

/* ========================================
// Horizontal Rule
// ===================================== */

hr {
  border: 0;
  border-bottom: 1px solid #ccc;
  margin: em($push) 0;
}
