/* ========================================
// Grid / Columns
// ===================================== */

.grid {

  &:after {
    @mixin clear;
  }

  &--20 {
    margin: 0 em(-10);
  }

  &--push-bottom {
    margin-bottom: em(50);
  }

}

.flex {
  display: flex;
  flex-wrap: wrap;

  &--centered {
    align-content: center;
    align-items: center;
  }

  &--justified {
    justify-content: space-around;
  }

}

  [class^='col-'] {
    float: left;
    width: 100%;

    .grid--20 & {
      margin-bottom: em(20);
      padding: 0 em(10);
    }

  }

  @media (min-width: $mini) {

    .col-mini {
      create-grid: 2;
    }

  }

  @media (min-width: $tiny) {

    .col-tiny {
      create-grid: 2;
    }

  }

  @media (min-width: $small) {

    .col-small {
      create-grid: 12;
    }

  }

  @media (min-width: $medium) {

    .col-medium {
      create-grid: 12;
    }

  }

  @media (min-width: $big) {

    .col-big {
      create-grid: 12;
    }

  }

  @media (min-width: $large) {

    .col-large {
      create-grid: 12;
    }

  }

  @media (min-width: $huge) {

    .col-huge {
      create-grid: 12;
    }

  }

  @media (min-width: $wooser) {

    .col-wooser {
      create-grid: 12;
    }

  }

  @media (min-width: $yatterman) {

    .col-yatterman {
      create-grid: 12;
    }

  }

  @media (min-width: $gamagori) {

    .col-gamagori {
      create-grid: 12;
    }

  }

  @media (min-width: $aldnoah) {

    .col-aldnoah {
      create-grid: 12;
    }

  }

  @media (min-width: $penguindrum) {

    .col-penguindrum {
      create-grid: 12;
    }

  }


.col {
  &--flex {
     display: flex;
     flex-wrap: wrap;
   }
  &--right {
    &-aldnoah {
      @mixin media $aldnoah {
        float: right;
        text-align: right;
      }
    }
  }

}