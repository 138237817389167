.profile-image {
  max-width: 200px;
  float: left;
  margin-right: 20px;
  margin-bottom: 5px;

  img {
    height: auto;
  }
}
