/*
    Content Meta Styles
*/

.meta {

    &--tag {
        color: $m3;
        font-size: em(14);
         font-style: italic;
         a {
          text-decoration: none;
         }
    }

     &--timestamp {
        color: $s3;
        font-size: em(12);
        font-style: italic;
      }

      &--publish-data {
        font-size: em(14);
        font-weight: bold;
        margin-bottom: 1em;
        text-transform: uppercase;
        color: $s3;
       }


}