/* ========================================
// Breadcrumbs Navigation
// ===================================== */

.nav-breadcrumbs {
  display: none;
  @mixin media $silkDrawerDisappears {
    display: block;
    margin-bottom: em(20);
    position: absolute;
    top: -81px;
    right: 0;
  }

  ul {
    background: $s1;
    padding: 0 em(10);
    text-align: right;
  }

    li {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      padding: 2px;

      &:not(:last-child) {

        &:after {
          display: inline-block;
          content: "\f054";
          color: $s4;
          margin-left: 3px;
          padding-bottom: 1px;
          @mixin fa;
          font-size: 8px;
        }

      }

    }

      a {
        display: inline-block;
        color: inherit;
        font-size: 14px;


        &:hover {}
          
      }

}
