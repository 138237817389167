/* ========================================
// Hero
// ===================================== */

.body-light .hero--full {
  height: 500px;
  @mixin media $large {
    height: calc(100vh - 98px);
  }
}

.hero {
  position: relative;
  background-color: #423c3c !important;
  background-position: 50% 50% !important;
  background-size: cover !important;

&--empty {
   @mixin background-image-properties;
   height: 40vw;
   min-height: em(240);
   max-height: em(500);
 }

&--default {
   @mixin background-image-properties;
   padding-top: em(100);
   padding-bottom: em(20);
 }

&--full {
   height: calc(100vh - 178px);
   position: relative;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   @mixin background-image-properties;
&:after {
   content: '';
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;

   background: -moz-linear-gradient(45deg,  rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 75%, rgba(0,0,0,0) 95%);
   background: -webkit-linear-gradient(45deg,  rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 75%,rgba(0,0,0,0) 95%);
   background: linear-gradient(45deg,  rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 75%,rgba(0,0,0,0) 95%);
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#00000000',GradientType=1 );
 }

&:before {
   content: '';
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;

   background-color: #deb807;
   opacity: 0.1;
 }

.hero--content {
  color: #fff;
  max-width: 600px;
  position: absolute;
  bottom: 100px;
  left: 0;
  z-index: 1;
  padding: 20px;
@mixin media $large {
  margin-left: 50px;
  bottom: 25vh;
  padding: 0;
}
@mixin media $penguindrum {
  max-width: 700px;
}
.hero-title {
  font-size: em(26);
  line-height: 1.3;
@mixin media $large {
  font-size: em(38);
}
@mixin media $penguindrum {
  font-size: em(45);
}
}
}
}

&--short {
   height: 500px;
   position: relative;
    @mixin background-image-properties;
&:after {
   content: '';
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: -moz-linear-gradient(45deg,  rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 75%, rgba(0,0,0,0) 95%);
   background: -webkit-linear-gradient(45deg,  rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 75%,rgba(0,0,0,0) 95%);
   background: linear-gradient(45deg,  rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 75%,rgba(0,0,0,0) 95%);
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#00000000',GradientType=1 );
 }
.hero--content {
  color: #fff;
  padding-top: em(160);
  max-width: 600px;
  z-index: 2;
  position: relative;
.hero-title {
  font-size: em(22);
@mixin media $big {
  font-size: em(34);
}
}
}
}

&--xshort {
   height: 250px;
 }

&--shade {

&:before {
 @mixin cover;
   content: '';
   background: rgba(0, 0, 0, .3);
 }

> * {
    position: relative;
  }

}

}

.hero-heading {
&--white {
   color: $s6;
 }
}

.page-hero {
  background-color: $s1;
}

.ko-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
@mixin media $huge {
  display: block;
}
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: none;
  }
  svg {
    width: 100%;
  }
.cls-1 {
  fill: #fff;
  opacity: 0.4;
  transform: translateZ(0);
  animation: fade-out 1.2s 1s forwards linear;
}
}

.page-hero--content {
@mixin media $wooser {
  margin-top: em(100);
  padding-bottom: em(100);
}
}

.hero--xshort .hero--content-inner {
    padding-top: 70px;
    color: #fff;
}



.slide-up {
    @mixin media $huge {
      opacity: 0;
      transform: translateY(100px);
      animation: slide-up forwards 0.6s 2.5s;
    }
}

.hero-btn-wrapper {
    @mixin media $huge {
      opacity: 0;
      transform: translateY(100px);
      animation: slide-up forwards 0.6s 2.9s;
    }
}


@keyframes slide-up {
  0%   {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/*
@keyframes slide-up-swing {
  0%   {
    opacity: 0;
    transform: translateY(100px) scale(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}*/

@keyframes fade-out {
  0%   {
    opacity: 0.4;
  }
  100% {
    opacity: 0.1;
  }
}
