/* ========================================
// Shared Navigation
// ===================================== */

.nav {

  ul,
  ol {
    @mixin list-reset;
  }

    a {
      display: block;
      text-decoration: none;
    }

}
