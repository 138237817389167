.owl-item {
	display: inline-block;
}
.owl-stage-outer {
	overflow: hidden;
}
.owl-prev,
.owl-next {
	position: absolute;
	top: 50%;
	color: $m1;
	font-weight: bold;
	cursor: pointer;
}
.owl-prev {
	left: 20px;
}
.owl-next {
	right: 20px;
}
.owl-loaded {
	.fa {
		transition: 0.1s;
		&:hover {
			transform: scale(1.1);
		}
	}
}

.home-event-carousel {
	position: relative;
	padding-bottom: 80px;
	.owl-prev,
	.owl-next {
		background: #333;
		padding: 10px;
		top: 100px;
	}
	.owl-prev {
		left: em(-10);

	}
	.owl-next {
		right: em(-10);
	}
	.disabled {
		display: none;
	}
}

#home-programs {
	height: em(500);
}

.program-carousel {
	position: relative;
	.fa {
		font-size: 30px;
		transition: 0.1s;
		&:hover {
			transform: scale(1.1);
		}
	}
	.slide {
		height: em(500);
		position: relative;
		overflow: hidden;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(0,0,0,0.2);
			transition: 0.2s;
			z-index: 1;
		}
		&:before {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 55%);
			background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 55%);
			content: '';
			opacity: 0;
			-webkit-transform: translate3d(0,50%,0);
			transform: translate3d(0,50%,0);
			transition: opacity 0.2s, transform 0.2s;
		}
		@mixin media $large {
			&:hover:before {
				opacity: 1;
				transform: translate3d(0,0,0);
			}
			&:hover {
				.slide-info .slide-desc {
					opacity: 1;
					transition-duration: 0;
					transform: translate3d(0, 0, 0);
				}
				.slide-info .slide-cat {
					opacity: 1;
					transition-duration: 0;
					transform: translate3d(0, 0, 0);
				}
				.slide-info .slide-title {
					transition-duration: 0;
					transform: translate3d(0, 0, 0);
				}
			}
		}
		.slide-info {
			position: absolute;
			bottom: 40px;
			width: 100%;
			color: #fff;
			text-align: left;
			z-index: 10;
			padding-left: em(40);
			h3 {
				margin: 0;
			}
			.slide-cat {
				color: $m1;
				font-weight: 700;
				text-transform: uppercase;
				transition: 0.2s;
				position: relative;
				font-size: 14px;
				@mixin media $large {
					opacity: 0;
					transform: translate3d(0, 20px, 0);
				}
			}
			.slide-title {
				margin-top: em(10);
				margin-bottom: em(10);
				transition: 0.2s;
				transform: translate3d(0, 20px, 0);
			}
			.slide-desc {
				font-weight: 600;
				opacity: 0;
				transition: 0.2s;
				position: relative;
				transform: translate3d(0, 20px, 0);
			}
		}
	}
	.disabled {
		display: none;
	}
}

#programs-sm {
	height: em(250);
}


.program-carousel-sm {
	position: relative;
	.slide {
		height: em(250);
		position: relative;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(0,0,0,0.2);
			z-index: 1;
			transition: 0.2s;
		}
		&:hover:after {
			background: rgba(0,0,0,0.5);
		}
		.slide-info {
			position: absolute;
			bottom: 40px;
			width: 100%;
			color: #fff;
			text-align: left;
			z-index: 10;
			padding-left: 20px;
			h3 {
				margin: 0;
				font-size: em(20);
			}
		}
	}
	.disabled {
		display: none;
	}
}

#home-stats {
	height: em(450);
}

.stats-carousel {
	position: relative;
	.slide {
		height: em(450);
		.slide-info {
			display: table;
			width: 100%;
			height: 100%;
			.slide-info--inner {
				display: table-cell;
				vertical-align: middle;
				padding: em(30);
				@mixin media $large {
					padding: em(20) em(80);
				}
			}

			.stat-title {
				font-weight: 700;
				display: inline-block;
				margin-bottom: em(10);
				@mixin h3
			}

			.icon-box {
				max-width: em(300);
				margin: 0 auto;
				text-align: center;
				img {
					max-width: em(60);
					display: inline-block;
				}
			}
		}
	}
	.disabled {
		display: none;
	}
}

