/* ========================================
// Site Header
// ===================================== */

.site__header {
  position: relative;
  background: none;

  .visible-drawer & {
    background: $s1;
  }
}
  .hat {}

  .scarf {
    @mixin media $medium {
      padding-right: em(40);
      padding-left: em(40);
    }
  }

.site-header--dark {
  background: #000;
}


.site-header--light {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,0.5+63,0+100 */
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.3) 63%, rgba(0, 0, 0, 0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.3) 63%, rgba(0, 0, 0, 0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.3) 63%, rgba(0, 0, 0, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=0); /* IE6-9 */
  position: absolute;
  width: 100%;
}

.logged-in .site__header {
  position: relative;
}
