.itemized-list {
    @mixin list-reset;

    li {
        padding: em(25) 0;
        border-bottom: 1px solid $s4;
    }

    .title {
        margin-bottom: em(5);
        a {
            color: $s2;
            text-decoration: none;
            &:hover {
                color: $m3;
             }
        }
    }
}

/**
Divided List
Used on: Related Resources
 */
/*.divided-list {
    @mixin list-reset;
    @mixin media $big {
        column-count: 2;
    }

    @mixin media $yatterman {
        column-count: 3;
    }

    &--related {
        a {
            color: $s2;
            font-size: em(18);
            padding: 0.25em;
            display: inline-block;

            &:hover {
                color: $m3;
             }

            &:after {
                content: '';
                display: inline-block;
                background: url('../icons/chevron-right-resource.svg') 50% 50% no-repeat;
                height: em(10);
                width: em(10);
                margin-left: 0.5em;
             }
        }
    }

}*/

/**
 * Inline Image
 **/
.inline-list {
    @mixin list-reset;
    li {
        display: inline-block;
    }
}

.nav-legal ul{
    @mixin list-reset;
}