/* ========================================
// Silk Harmonica
// ===================================== */

/* When do accordions become harmonicas? */
$accordionToHarmonica: 900px;

.silk-harmonica {
  margin-bottom: em(30);
  
  &--expanded {

    @media(min-width: $accordionToHarmonica) {
      display: flex;
      flex-wrap: wrap;
    }

     .silk-harmonica__header.active {
      @mixin media $huge {
        border-bottom: 8px solid $m3;
      }
    }
    .silk-harmonica__header:hover {
      @mixin media $huge {
        border-bottom: 8px solid $m3;
      }
    }
    
  }

}

  .silk-harmonica__header {
    position: relative;
    cursor: pointer;

    .plus-minus {
      &:before {
        position: absolute;
        content: '';
        width: 15px;
        height: 3px;
        background: #000;
        bottom: 20px;
        right: 0;
        transition: 0.2s;
      }
      &:after {
        transform: rotate(90deg);
        position: absolute;
        content: '';
        width: 15px;
        height: 3px;
        background: #000;
        bottom: 20px;
        right: 0;
        transition: 0.2s;
      }
    }
    &.active {
      .plus-minus {
        &:before {
          opacity: 0;
        }
        &:after {
          transform: rotate(0deg);
        }
      }
    }

    .silk-harmonica--expanded & {

      @media(min-width: $accordionToHarmonica) {
        flex: 1 auto;
        order: 1;
      }

    }

    &:hover {}

    &.active {

      + * {
        display: block;
      }

    }

    & > *:first-child {
      margin-bottom: 0;
      margin-top: 20px;
    }

  }

    .silk-harmonica__bottle {
      position: absolute;
      top: 50%;
      right: em(20);
      transform: translate(0, -50%);
      display: none;

      &:first-of-type {

        .active & {
          display: none;
        }

      }

      &:last-child {
        display: none;

        .active & {
          display: block;
        }

      }

      .silk-harmonica--expanded & {

        @media(min-width: $accordionToHarmonica) {
          visibility: hidden;
        }

      }

    }

      .silk-harmonica__symbol {
        display: block;
      }

  .silk-harmonica__content {
    display: none;

    .silk-harmonica--expanded & {

      @media(min-width: $accordionToHarmonica) {
        order: 2;
        width: 100%;
      }

    }

  }

.silk-harmonica--expanded {
  .plus-minus {
    display: inline;
    @mixin media $huge {
      display: none;
    }
  }
}




