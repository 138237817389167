/* ========================================
// Forms
// ===================================== */

/* Container
// ------------------------------------- */

form {}

  fieldset {
    border: 0;
    margin-bottom: em($push);
    padding: 0;
  }


/* Label
// ------------------------------------- */

label {
  margin-right: em(20);
}

input {
  border: 1px solid #ccc;
  color: #333;
}

.input-field {
  font-style: italic;
}

.input-search .input-symbol {
  top: 51%;
}


/* Shared Input/Select/Textarea
// ------------------------------------- */

@define-mixin form-item-container {
  position: relative;
  overflow: hidden;
}

@define-mixin form-item {
  width: 100%;
  padding: em(10) em(20);
  border: none;
  appearance: none;
  background: #fff;
  border-radius: 0;
}

.input-field {
  @mixin form-item-container;
}

.input-hint {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: em(50);
  background: $m1;
  color: $s2;
  transition: background .2s;
  pointer-events: none;
  &:hover {
    background: darken($m1, 0.8);
  }

   .input-search & {
     background: none;
   }
}

    .input-symbol {
      pointer-events: none;
      top: 56%;

      svg {
        fill: #000 !important;
        z-index: 40;
      }

    }


/* Input
// ------------------------------------- */


input {

  &:disabled {}

  &:checked {}

  &[type='text'],
  &[type='email'],
  &[type='file'] {
    @mixin form-item;
    background: #fff;
    border: 1px solid #ccc;
    cursor: pointer;
  }

  &[type='radio'],
  &[type='checkbox'],
  &[type='password'] {
    height: em(15);
    width: 100%;
    border: 1px solid;
    cursor: pointer;
    appearance: none;
    transition:
      border .2s,
      background .2s;
      border: 1px solid #ccc;
      @mixin form-item;

    &:checked {
      background: currentColor;
    }

  }

  &[type=checkbox] {
    width: 15px;
    padding: 0;
    border: 1px solid;
  }

  &[type='radio'] {
    border-radius: 100%;
    width: 20px;
    height: 20px;
    padding: 0;
    border: 1px solid;
  }

  &[type='range'] {}

  &[type='password'] {
    border: 1px solid #ccc;
    padding: em(22) em(20);
  }

}

.hidden-submit {
  z-index: 20;
  opacity: 0;
}


/* Textarea
// ------------------------------------- */

textarea {
  @mixin form-item;
  background: #fff;
  border: 1px solid #ccc;
}


/* Select
// ------------------------------------- */

select {
  @mixin form-item;
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 8px 20px;
  margin-bottom: em(20);

  &:hover {
    + .input-hint {
      background: darken($m1, 0.8);
    }

  }

  + .input-hint {
    pointer-events: none;
  }

}

.news--form {
  select {
    margin-bottom: 0px;
  }
}


/* ========================================
// Unique Form Elements
// ===================================== */

.header-dark .site-search__text {
  border-bottom: 3px solid #000;
}

.header-light .site-search__text {
  border-bottom: 3px solid $m1;
}

.site-search {
  position: relative;

  @mixin media $silkDrawerDisappears {
    height: em(50);
    width: em(50);
  }

}

  .site-search__trigger {
    position: absolute;
    height: 25px;
    width: 25px;
    cursor: pointer;
    right: 70px;
    top: 37px;
    font-size: 28px;
    @mixin media $silkDrawerDisappears {
      /*height: 100%;*/
      /*width: 100%;*/
      font-size: 18px;
      right:45px;
      top: 13px;
    }

    .vertically-center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

      svg {
        display: block;
        width: 25px;
        height: 25px;
      }

  }

  .site-search__details {
    position: absolute;
    opacity: 0;
    width: 100%;
    left: 0;

    @mixin media $silkDrawerDisappears {
      position: absolute;
      top: 100%;
      right: em(60);
      opacity: 0;
      transform: scale(.5);
      transform-origin: right top;
      transition:
        opacity .2s,
        transform .2s;
    }

    .event-triggered & {
      opacity: 1;
      @mixin media $silkDrawerDisappears {
        opacity: 1;
        transform: scale(1);
      }

    }

    .site-search__text {
        width: em(300);
        float: right;
        background: #fff;
        border: none;
        -webkit-appearance: none;
    }

    .site-search__submit {

      position: absolute;
      top: 0;
      right: 0;
      height: calc(100% - 3px);
      border: 0;
      padding: 0 em(20);
      opacity: 1;
      transition: background .3s;
      background-color: transparent;
      color: #fff;
      background-image: url(../icons/search.svg);
      background-repeat: no-repeat;
      background-position: 50%;
      width: 44px;
      height: 44px;
      font-size: 0px;

      @mixin media $silkDrawerDisappears {
        opacity: 1;
      }

      &:hover {}

    }

  }

  .news--form{
    select {
      border: none;
      background: #f1f1f1;
    }
    input[type='text'] {
      border: none;
      background: #f1f1f1;
    }
    .keyword-title {
      visibility: hidden;
    }
  }

  .form--standard {
    input {
      margin-bottom: em(20);
    }
  }

.webform-client-form {

  input[type='email'],
  input[type='text'],
  .form-textarea-wrapper{
    margin-bottom: em(20);
  }

  input[type='submit'] {
    border: 1px solid;
    background: none;
    color: #E31933;
    font-size: 0.7777777777777778em;
    font-weight: 700;
    padding: 1em 2em;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    margin-top: 20px;
    &:hover {
       color: #fff;
       background: #E31933;
       border-color: #E31933;
     }
  }
}


.date-picker-wrapper table .caption {
    background: $m1;
    color: #333;
}

.date-picker-wrapper .month-wrapper table .day.toMonth.valid:hover {
  background: $m1;
}

.date-picker-wrapper .month-wrapper table .day.toMonth.valid {
  background-color: #f1f1f1;
  transition: 0.2s;
}

.date-picker-wrapper .month-wrapper table .day.toMonth.valid.checked {
  background-color: #ffea88;
}

.date-picker-wrapper .month-wrapper table .day.toMonth.valid.checked.first-date-selected,
.date-picker-wrapper .month-wrapper table .day.toMonth.valid.checked.last-date-selected {
  color: $m1;
}

.date-picker-wrapper .drp_top-bar .apply-btn {
  border-radius: 0;
  -webkit-appearance: none;
  background: $m1;
  border: none;
  color: #000;
}

.date-picker-wrapper .drp_top-bar .apply-btn.disabled {
  background-color: #e1e1e1;
}

.date-picker-wrapper .month-wrapper table .day.real-today {
  background-color: $m1;
}

.month-wrapper table th.month-name {
  background: rgba(255,255,255,0);
}

.date-picker-wrapper .date-range-length-tip {
  background: $m1;
  color: #333;
}

.month-wrapper table th:not(.month-name) {
  background: rgba(255,255,255,0);
  color: #333;
  font-size: 12px;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.date-picker-wrapper .first-date-selected {
    background-color: $m1 !important;
    color: #000 !important;
}

.date-picker-wrapper .last-date-selected {
    background-color: $m1 !important;
    color: #000 !important;
}

.node-type-page section form {
  margin-bottom: 20px;
}
