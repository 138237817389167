.nav-utility {
    display: none;

    a {
        color: $m1;
        font-size: em(14);
        text-transform: uppercase;
        font-weight: bold;
        padding: 0.5em 0.5em;
        &:hover {
            text-decoration: underline;
        }
        &.active {
          color: $m1;
        }
    }

    @mixin media $silkDrawerDisappears {
        text-align: right;
        display:block;
        >ul {
            margin-right: em(40);
            margin-top: 10px;
         }

        > ul {
            > li {

                position: relative;
                display: inline-block;
            }
        }
    }

    &--mobile {
        display: block;
        background: #292F35;
        padding: 1em;
        font-weight: 400;
        column-count: 2;
        li {
            padding: 0.5em 0;
        }
     }
}
