/* ========================================
// Silk Modal
// ===================================== */

.silk-modal {
  position: relative;
  margin-bottom: em($push);
}

  .silk-modal__trigger {
    position: absolute;
    top: 50%;
    left: 50%;
    cursor: pointer;
    transform:
      translate(-50%, -50%)
      scale(1);
    transition: transform .2s;

    &:hover {
      transform:
        translate(-50%, -50%)
        scale(1.25);
    }

    svg {
      fill: #fff;
    }

  }

  .silk-modal__details {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 800;
    display: none;
    /*overflow: scroll;
    webkit-overflow-scrolling: touch;
    height: 90%;
    height: calc(100% - em(80));
    max-width: em(1300);
    width: 100%;
    width: calc(100% - em(80));*/
    padding: em(20);
    background: #fff;
    box-shadow: 0 0 em(8) 0 rgba(0, 0, 0, .25);
    transform: translate(-50%, -50%);

    .event-triggered & {
      display: block;
    }

    img {
      max-height: 80vh;
      max-width: 80vw;
      width: auto;
    }

  }

    .silk-modal__trigger--close {
      margin-bottom: em(10);
      cursor: pointer;
      transition: color .2s;

      &:hover {}

      svg {
        display: inline-block;
        vertical-align: middle;
        fill: currentColor;
      }

      span {
        display: inline-block;
        vertical-align: middle;
        margin-left: em(10);
      }

    }


/* Effect on body */

.modal-triggered {
  overflow: hidden;
}
