/*
    Overrides that I'm not to proud of.
 */
.black-panel {
    .button {
        color: $m1;
        border-color: $m1;
        &:hover {
            background: $m1;
            color: $s1;
         }
    }
}
.html {
    li.expanded, li.collapsed, li.leaf {
        padding: 0;
        margin: 0;
    }

    ul li.leaf {
        list-style-image: none;
        list-style-type: none;
    }
}

.logged-in tabs.primary {
    a {
        color: $m3;
    }
}

ul li.collapsed {
    list-style-image: none;
    list-style-type: none;
}