.calendar-grid {
    overflow-x: scroll;

    @mixin media $gamagori {
        overflow-x: visible;
    }

    .calendar__steering {
      display: inline-block;
      padding-top: 35px;
      padding-left: 10px;
      padding-right: 10px;
      vertical-align: middle;
      &.calendar__prev {
        float: left;
        svg {
          float: left;
        }
      }
      &:hover {
        cursor: pointer;
      } 
      &.calendar__next {
        float: right;
      }
    }

    .calendar__controls {
      background: $s2;
      color: #fff;
      text-align: center;
      h3 {
        display: inline-block;
        margin: 0;
        padding: em(8) 0;
        font-size: em(42);
        font-weight: 400;
      } 
      svg {
        position: relative;
        top: 4px;
        path {
          fill: #fff;
        }
      }
    }


    table {
        width: 100%;
        font-size: em(15);

       a {
           color: $bodyColor;
           font-weight: bold;
           text-decoration: none;
           &:hover {
            text-decoration: underline;
           }
       }

       th {
           width: 14.2%;
           padding: rem(10);
           text-align: center;
            background: #aaa;
            color: #f0f0f0;
            border-right: 1px solid #aaa;
       }

        tr {
            td {
                border: 1px solid $s4;
                height: 200px;
                vertical-align: top;
                &.inactive {
                    a {
                        opacity: .25;
                    }
                }

            }

            &:nth-of-type(odd) td{
                background: none;
                border-bottom: 1px solid $s4;
                padding: rem(6) rem(12);
             }

            &:nth-of-type(even) td{
                 background: none;
             }
        }
    }


}

.calendar-grid.calendar-small {
  @mixin media $large {
    padding-left: em(30);
    padding-right: em(30);
  }
  .calendar__steering {
    padding-top: 5px;
  }
  .calendar__controls {
    h3 {
      font-size: em(16);
      font-weight: 600;
    }
  }
  table {
    th {
      font-size: em(16);
    }
    tr {
      td {
        height: 60px;
        text-align: center;
      }
    }
  }
}


.views-section {
  .events-view {
    opacity: 0;
    display: none;
    transition: 0.2s;
    &.active {
      opacity: 1;
      display: block;
    }
    &.calendar {
      padding-top: em(60);
      padding-bottom: em(60);
    }
  }
}

.toggle-event-view {
  text-align: right;
  margin-top: em(30);
  display: none;
  @mixin media $large {
    display: block;
  }
  .cal-view {
    position: relative;
    top: 6px;
    fill: $s2;
    svg {
      width: 40px;
      height: 40px;
    }
  }
  .active {
    fill: $m3;
  }
}

.featured-event {
  background-color: #f1f1f1;
  border-top: 6px solid $m3;
  padding: em(20);
  padding-top: em(60);
  margin-top: em(20);
  margin-bottom: em(40);
  position: relative;
  @mixin media $large {
    padding: em(60);
  }
  &--flag {
    position: absolute;
    top: 0;
    left: 0;
    padding: em(15) em(40);
    background-color: $m3;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
  }
  &--title {
    color: $m3;
    font-size: em(26);
    font-weight: 300;
    @mixin media $large {
      font-size: em(44);
    }
    a {
      color: $m3;
      text-decoration: none;
      &:hover {
        color: #000;
      }
    }
  }
  &--date {
    font-weight: 700;
  }
  &--type {
    color: $m3;
    font-style: italic;
    font-size: em(14);
  }
}
