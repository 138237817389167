/*
    Resources Page (View)
 */
.view-resources, .view-students {
    .view-filters {
        .views-widget-filter-title,
        .views-widget-filter-field_tags_tid {
            width: 100%;
            @mixin media $large {
                width: 33%;
            }
        }

        .views-widget-filter-title {
            @mixin media $large {
                width: 50%;
            }
        }

        .form-text {
            background-color: #f1f1f1;
            background-image: url('../icons/search-blk.svg');
            background-repeat: no-repeat;
            background-position: 96% 50%;
        }

        .views-submit-button {
            margin-top: 1.5em;
            background: none;
        }

        .form-submit {
            margin-top: 0;
            color: #121B21;
            background: #FFD200;
            padding: .5em 1.5em;
            font-weight: 700;
            border: 2px solid #FFD200;
            width: 100%;
            margin-bottom: .5555555555555556em;
        }

        .form-type-select {
            position: relative;

            &:after {
                 content: "";
                 display: block;
                 position: absolute;
                 top: 0;
                 right: 0;
                 height: 100%;
                 width: 2.7777777777777777em;
                 background: url('../icons/chevron-down.svg') no-repeat #FFD200 center center;
                 color: #434A4F;
                 -webkit-transition: background .2s;
                 transition: background .2s;
                 pointer-events: none;
             }
        }
    }
    .item-list {
        text-align: center;
        margin-bottom: 2em;

    }
}

.view-students {
  .student-content {
    margin-top: 20px;
  }
  .student {
    @mixin media $large {
        padding-right: 20px;
    }
  }
  .bg-img .bg-quote {
    height: 77%;
  }

}

.student {
  .block--quote {
    height: 340px;
  }
}
.pager {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.125rem;
    line-height: 1.5555555555555556;
    list-style: none;
    display: inline-block;
    vertical-align: top;
    padding: 0 1.1111111111111112em;

    a {
        color: $m3;
        padding: 0.5em;
    }

    li {
        display: inline-block;
    }
}
