/* ========================================
// Symbols
// ===================================== */

.symbol {
  transition:
    fill .2s,
    stroke .2s;

  &-home,
  &-undo {
    height: em(16);
    width: em(16);
  }

  &-chevron-left,
  &-chevron-right {
    height: em(20);
    width: em(20);
  }

  &-chevron-down {
    height: em(30);
    width: em(30);
     transition:
             fill .1s,
             stroke .1s;
  }

  &-close {
    height: em(20);
    width: em(20);
  }

  &-minus,
  &-plus {
    height: em(20);
    width: em(20);

  }

  &-play {
    height: em(65);
    width: em(65);
  }

  &-search {
    height: em(30);
    width: em(30);
    fill: $m1;
  }

   &-calendar {
      height: em(20);
      width: em(20)
    }

}
