/* ========================================
// Unique Navigation
// ===================================== */

.nav-tier {
    border-top: 1px solid $s7;
    border-bottom: 1px solid $s7;


/*    @mixin media $medium {
        margin-top: em(30);
    }*/

    .tier-trigger {
        display: block;
        color: $m1;
        padding: em(15) 0;
        cursor: pointer;
        @mixin media $silkDrawerDisappears {
            display: none;
        }
        .fa {
            float: right;
            font-size: 14px;
            margin-top: 5px;
            transition: 0.4s;
        }
        &.active .fa {
            transform: rotate(180deg);
        }
    }

    &.active-tier {

        ul {
            display: block;
        }

    }

    ul {
        @mixin list-reset;
         padding-top: em(10);
        padding-bottom: em(10);
        display: none;

        @mixin media $silkDrawerDisappears {
            display: block;
        }

    }

    li {

        @mixin media $medium {
            display: inline-block;
            vertical-align: top;
            width: 49%;
        }

        @mixin media $huge {
            width: 32%;
        }

        @mixin media $gamagori {
            width: 24%;
        }

    }

    a {
        display: inline-block;
        padding: em(5, 16) 0;
        font-size: em(16);
        color: $m1;
        text-decoration: none;

        &:hover {
             color: #fff;
         }

    }

}

.nav-tier__trigger {
    position: relative;
    padding: em(15) 0 em(14);
    color: $m2;

    svg {
        fill: $m1;
    }

    @mixin media $silkDrawerDisappears {
        display: none;
    }

    span {

        &:nth-child(1),
        &:nth-child(2) {
             font-size: em(16);
             font-weight: 500;
             line-height: 1;
             /*text-transform: uppercase;*/
         }

        &:nth-child(1) {
             display: block;

            .active-tier & {
                display: none;
            }

        }

        &:nth-child(2) {
             display: none;

            .active-tier & {
                display: block;
            }

        }

        &:nth-child(3) {
             position: absolute;
             top: 50%;
             right: 0;
             transform:
                     translate(0, -50%)
                     scaleY(1);
             transition: transform .2s;

            .active-tier & {
                transform:
                        translate(0, -50%)
                        scaleY(-1);
            }

        }

    }

}
