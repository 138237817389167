/* ========================================
// Silk Type
// ===================================== */

@import url(https://fonts.googleapis.com/css?family=Lora:400,400italic);


/* ========================================
// Silk Header
// ===================================== */

.site__header {
  position: relative;
  z-index: 20;
  background: #fff;

  .compartment {
    padding: em(18) em(30);

    @mixin media $silkDrawerDisappears {
      display: flex;
    }

  }

}


/* ========================================
// Silk Footer
// ===================================== */

.site__footer {
  position: relative;
  z-index: 20;
  background: #fff;
}


/* ========================================
// Silk Drawer Trigger
// ===================================== */

.drawer__trigger {
  right: em(10);

  &:before,
  &:after {
    color: $m1;
  }

}


/* ========================================
// Silk Nav
// ===================================== */

.nav-main {

  @mixin media $silkDrawerDisappears {
    padding-left: em(10);
    text-align: right;
  }

  > ul {

    > li {

      &:last-child {

        > a {
          padding-right: 0;
        }

      }

      > ul {

        @mixin media $silkDrawerDisappears {
          padding: em(10) 0;
          text-align: left;
        }

      }

    }

  }

    a {
      color: $m1;

      @mixin media $silkDrawerDisappears {
        padding: 0 em(10);
      }

    }

}


/* ========================================
// Silk Content
// ===================================== */

.compartment {

  > section {
    padding: em(60) em(30);

    &:nth-of-type(odd) {
      background: $m1;
      color: #fff;

      a {
        color: inherit;
      }

    }

  }

}


/* ========================================
// Silk Accordion / Tabs
// ===================================== */

.silk-harmonica__header {
  border-bottom: 2px solid $m3;
  padding: em(10);
/*  background: rgba(0, 0, 0, .05);*/
  transition: background .2s;

  &:hover {
    /*background: rgba(0, 0, 0, .125);*/
  }

}

  .silk-harmonica__symbol {
    fill: #fff;
  }

.silk-harmonica__content {
  padding: em(10);
}


/* ========================================
// Silk Collage
// ===================================== */

.silk-collage {
  @mixin list-reset;
  position: relative;
  border-top: 1px solid;
  border-left: 1px solid;
  height: em(400);
  counter-reset: collage;

  li {
    position: absolute;
    border-right: 1px solid;
    border-bottom: 1px solid;
    text-align: center;
    counter-increment: collage;

    &:nth-child(1) {
      @mixin collage 2, 3, 0, 0, 6, 6;
    }

    &:nth-child(2) {
      @mixin collage 2, 3, 0, 3, 6, 6;
    }

    &:nth-child(3) {
      @mixin collage 2, 6, 2, 0, 6, 6;
    }

    &:nth-child(4) {
      @mixin collage 2, 1, 4, 0, 6, 6;
    }

    &:nth-child(5) {
      @mixin collage 2, 4, 4, 1, 6, 6;
    }

    &:nth-child(6) {
      @mixin collage 2, 1, 4, 5, 6, 6;
    }

    &:before {
      @mixin h4;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      content: counter(collage);
      transform: translate(0, -50%);
    }

  }

}


/* ========================================
// Swift Slide
// ===================================== */

.swift-slide {
  counter-reset: swift;
  text-align: center;

  li {
    counter-increment: swift;

    &:before {
      @mixin h1;
      content: counter(swift);
    }

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      height: em(200);
      width: em(200);
      border: 1px solid #fff;
      content: '';
      pointer-events: none;
      background: rgba(255, 255, 255, .75);
      border-radius: 100%;
      transform:
        translate(-50%, -50%)
        scale(0);
    }

    &.active {

      &:after {
        animation: burst .25s;
      }

    }

  }

}

  .swift-controls {

    button {
      padding: em(10) em(5);
      font-size: 150%;
    }

      svg {
        fill: #fff;
      }

  }

  .swift-pager {

    span {
      height: em(12);
      width: em(12);
      margin: em(5);
      border-radius: 100%;
      box-shadow: inset 0 0 0 em(2) #fff;

      &:hover {
        background: rgba(255, 255, 255, .5);
      }

      &.active {
        background: #fff;
      }

    }

  }

@keyframes burst {

  100% {
    opacity: 0;
    transform:
      translate(-50%, -50%)
      scale(1);
  }

}


/* ========================================
// Silk Filters
// ===================================== */

.pagination__control {
  font-size: 150%;

  svg  {
    fill: #fff;
  }

}


/* ========================================
// Silk Buttons
// ===================================== */

.button {
  font-size: em(14);

  @mixin media $medium {
    font-size: em(18);
  }

}


/* ========================================
// Silk Modal
// ===================================== */

.silk-modal {

  .silk-modal__details {
    color: $bodyColor;
  }

}


/* ========================================
// Silk Halfway
// ===================================== */

.anchor-set {
  mix-blend-mode: exclusion;
}

  .anchor-set__point {
    z-index: 40;
    border-color: $m1;

    &:before {
      background: $m1;
    }

  }

    .anchor-set__label {
      padding: em(10) em(20);
      background: $m1;
      color: #fff;
    }


/* ========================================
// Silk Halfway
// ===================================== */

.halfway-section {}

  .halfway-content {

    .halfway-section:nth-of-type(odd) & {
      background: $m1;
    }

    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      content: 'Halfway';
      font-family: $serif;
      font-size: 4vmin;
      text-align: center;
      transform: translate(0, -50%);
    }

  }


/* ========================================
// Silk Footer
// ===================================== */

.site__footer {

  .compartment {
    padding: em(20) em(30);
  }

}
