/* ========================================
// Sections
// ===================================== */

.content {
  padding: em(40) 0;
}

.main-intro {
  background: $s1;
  color: $s6
}

.pillow--20 {
  padding: em(20) 0;
}

.related-resources {
  background: $s5;
  padding: em(50) 0;

  .read-more {
    font-size: em(14);
    margin-top: 1em;
  }
  a {
    text-decoration: none;
  }
}

.grid--news-and-publication {
  [class^='col-'] {
      margin-bottom: 0;
    }
  .card {
    margin-bottom: em(20);
  }
  .block {
    margin-bottom: em(20);
    height: em(320);
  }
  .card-content {
    padding-top: em(18);
    padding-bottom: em(18);
  }
}

.blocks-more-links a {
  text-decoration: none;
}