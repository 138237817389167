/* ========================================
// Silk Table
// ===================================== */

.silk-table--wrap {
  width: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}


.silk-table {
  width: 600px;
  border-bottom: 1px solid #ccc;
  @mixin media $silkTableExpanded {
    overflow-x: visible;
    width: 100%;
  }
  thead {
  	background: #eee;
  	border-bottom: 1px solid #ccc;
  	font-size: em(20);
  	font-weight: 700;
  }
  td {
  	padding: em(15) em(10);
  }
  td:not(:last-of-type) {
  	border-right: 1px solid #ccc;
  }
}


