.page-header {
    background: $s1;
    color: $s6;
    position: relative;

    h2, h3, h4 {
        color: $m1;
    }

    .compartment {
        position: relative;
    }

    .block-lead-in {
        font-size: em(22);
        font-style: normal;
        @mixin media $large {
            font-size: em(28);
        }
    }

    .page-title {
        color: $m1;
        font-size: em(28);
        margin-bottom: em(10);
    }
}