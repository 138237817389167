.block {
    position: relative;
    height: em(270);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin-bottom: em(20);

    img {
        display: none;
    }

    p {
        margin: 0;
    }

    .meta--publish-data {
        font-size: em(14);
        margin-bottom: em(7);
        color: $m1;
    }

    blockquote {
        margin-bottom: 0;
        padding-left: 1.5em;
        &:before {
            content: '\201C';
            font-size: em(60);
            position: absolute;
            margin-top: -32px;
            margin-left: -46px;
         }
         &:after {
            content: '\201D';
        }
    }
}

.block-content {
    color: white;
    padding: em(20);
    position: absolute;
    bottom: 0;
    @mixin media $wooser {
        padding: em(30);
    }
}

.block-title {
    margin: 0;
    padding: 0;
    font-size: em(20);
    @mixin media $wooser {
        font-size: em(26);
        line-height: 1.2em;
        font-weight: 700;
    }
    &:hover {
        text-decoration: underline;
    }
}

.block--cat {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    color: $m1;
    padding-bottom: em(10);
}

.block--media {
    a {
        height: 100%;
        width: 100%;
        display: block;
        cursor: pointer;
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 60%);

    }
    &:hover {
        h4 {
            text-decoration: underline;
        }
    }
}


.block--news,
.block--news-w-excerpt {
    min-height: em(310);
}

/*.block--event {
    height: em(200);
}*/

.block--event,
.block--news-w-excerpt {
    .block-content {
      top: 0;
    }
}

.block--news {
    .block-content {
        padding: 1.5em;
        background: linear-gradient(to bottom,rgba(0,0,0,0) 0,rgba(0,0,0,.7) 60%);

    }
}

.block--news-w-excerpt {
    background-color: white;

    * {
        color: $s1;
    }

    .meta--publish-data {
        color: $s3;
    }


    .news-title {
        margin-bottom: em(8);
    }

    .read-more {
        color: $m3;
        margin-top: 1.25em;
    }

}

.block--event.block--event-short {
    height: em(250);
    .block-content {
        padding: em(40);
        padding-top: em(30);
    }
}


.block--event {
    height: em(270);
    background-color: $m1;

    * {
        color: $s1;
    }

    .block-content {
        padding: em(20);
        @mixin media $wooser {
            padding: em(40);
        }
    }


    .meta--publish-data {
        color: #9F8C00;
        margin-bottom: em(10)
    }

    .block-event-date {
        background: $m2;
        font-size: em(18);
        font-weight: bold;
        padding: 1em 2em;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-transform: uppercase;
        .input-symbol {
            padding-right: 15px;
            position: relative;
            top: 2px;
        }
    }
}
