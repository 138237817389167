/* ========================================
// Media
// ===================================== */

/* Figure
// ------------------------------------- */

figure {
  margin: 0;
}

.shifted-asset {
  margin-bottom: em(20);

  @mixin media $huge {
    max-width: em(380);
  }

  &--left {

    @mixin media $huge {
      float: left;
      margin-right: em(40);
    }

  }

  &--right {

    @mixin media $huge {
      float: right;
      margin-left: em(40);
    }

  }

}

  figcaption {
    margin-top: em(10);
    font-style: italic;
  }


/* Image
// ------------------------------------- */

img {
  display: block;
  max-width: 100%;
  height: auto;

  .hero > & {
    display: none;
  }

}


/* Iframe
// ------------------------------------- */

iframe {
  width: 100%;
}


/* SVG
// ------------------------------------- */

svg {}


/* Video
// ------------------------------------- */

video {}
