.red-arrow-links {
	padding-top: em(30);
	padding-bottom: em(30);
	@mixin media $large {
		column-count: 3;
		column-width: 360px;
	}
	li {
		padding-bottom: em(20);
	}
	a {
		color: #000;
		font-weight: 400;
		text-decoration: none;
		&:after {
			content: "\f054";
			color: $m3;
			padding-left: 5px;
			font-size: 12px !important;
			@mixin fa
		}
		&:hover {
			color: $m3;
		}
	}
}

.contact-info {
	@mixin media $big {
		display: inline-block;
		padding-right: 40px;
	}
	a {
		color: $m3;
	}
}

#program-stats {
	height: 400px;
	display: flex;
	position: relative;
	@mixin media $large {
		height: 450px;
	}
	.fa {
		color: #666;
		@mixin media $large {
			display: none;
		}
	}
}
.stat-lg {
	font-size: em(70);
	font-weight: 700;
	position: relative;
	left: 20px;
	top: -20px;
}
.stat-info {
	color: #aaa;
	font-weight: 700;
	font-size: em(20);
	text-transform: uppercase;
	position: relative;
	top: -15px;
}
.stat-single {
	height: 350px;
	margin-top: 50px;
	padding: em(40);
	text-align: center;
	border-right: 1px solid #ccc;
	display: table;
	&--inner {
		display: table-cell;
    vertical-align: middle;
    height: 100%;
    width: 100%;
	}
}


.content-info-box {
	padding: 80px 0 100px;
}

.video-image {
	width: 100%;
	height: 300px;
	position: relative;
	@mixin background-image-properties;

	.video-popup {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		i {
			color: #000;
			background: rgba(255,251,255,.3);
			padding: 20px 30px;
    	font-size: 38px;
			border-radius: 100%;
			width: 80px;
			height: 80px;
			text-align: center;
			transition: 0.2s;
			&:hover {
				transform: scale(1.1);
			}
		}
	}
}
.video-caption {
	font-style: italic;
	margin-top: 5px;
	color: #121B21;
}

.more-links {
	display: block;
	padding-bottom: 10px;
	@mixin media $large {
		display: inline-block;
		float: right;
		margin-top: 35px;
		padding-bottom: 0;
	}
	a {
		color: #000;
		text-decoration: none;
		&:first-of-type {
			padding-right: em(15);
		}
		&:after {
			content: "\f054";
			color: $m3;
			padding-left: 5px;
			font-size: 12px !important;
			@mixin fa
		}
		&:hover {
			color: $m3;
		}
	}
}


.three-col {
	padding-top: em(50);
	padding-bottom: em(40);
	.section-title {
		margin-bottom: 10px;
	}
	.clear {
		@mixin media $large {
			display: flex;
			margin-left: -10px;
			margin-right: -10px;
		}
	}
	.col-large-4 {
		@mixin media $large {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	.col-wooser-4 {
		@mixin media $large {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
}

.chat-block {
	background: #000;
	width: 100%;
	text-align: center;
	padding: 40px;
	img {
		width: 150px;
		border-radius: 100%;
		margin: 0 auto 20px;
	}
	.chat-info {
		max-width: 300px;
		margin: 0 auto;
		color: #fff;
		p {
			margin-bottom: 15px;
			line-height: 1.1;
			&.chat-title {
				font-size: em(20);
				line-height: 1;
			}
		}
		a {
			margin-bottom: 22px;
			color: #fff;
			position: relative;
			&:after {
				content: "\f054";
				position: absolute;
				padding-left: 15px;
				top: 5px;
				color: $m1;
				font-size: em(14) !important;
				@mixin fa
			}
			&:hover {
				color: $m1;
			}
		}
	}
}

.preview-block {
	background: #fff;
	border-top: 4px solid $m3;
	height: 700px;
	@mixin media $big {
		transform: translate3d(0, -40px, 0);
		/*height: calc(100% + 40px);*/
		height: 775px;
	}
	@mixin media $large {
		height: 673px;
	}
	@mixin media $wooser {
		transform: translate3d(0, -98px, 0);
		/*height: 100%;*/
	}
	&--title {
		font-size: em(28);
		margin-top: 0;
		@mixin media $wooser {
			font-size: em(38);
		}
	}
	&--content {
		padding: em(30);
		padding-top: em(40);
		height: 60%;
		@mixin media $yatterman {
			padding: em(50);
			padding-top: em(60);
		}
	}
	&--bg-img {
	 	@mixin background-image-properties;
		height: 40%;
		width: 100%;
	}
}

.news-events-grid {
	position: relative;
	color: #fff;
	&--inner {
		padding-top: em(80);
	}
	.more-links{
		 a{
		 	color: #fff;
		 	&:after {
		 		color: $m1;
		 	}
		 	&:hover {
		 		color: $m1;
		 	}
		}
	}
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #292f35;
		z-index: 0;
		@mixin media $large {
			background: rgba(18,27,33,.8);
		}
	}
	.compartment {
		z-index: 1;
		position: relative;
		padding: em(0) em(20);
	}
	.card-content {
		background: #fff;
		color: #000;
	}
}

.media-element-container .content {
	padding: 0;
}

.page-header {
	.student-panel {
		.video-caption {
			color: #fff;
		}
	}
}

.student-panel {
	&--content {
		padding-top: em(30);
		padding-bottom: em(30);
		@mixin media $large {
			padding: em(40);
			padding-left: 0;
		}
	}
	&--media {
	    img {
			height: auto;
		}
		@mixin media $large {
			padding: em(40);
		}
	}
}

.black-panel {
	background: #121B21;
	border-top: 4px solid $m3;
	color: #fff;
	&--solid {
		border-top: none;
	}
	.compartment {
		padding-top: em(35);
		padding-bottom: em(30);
	}
	.compartment--small {
		padding-top: em(60);
		padding-bottom: em(60);
	}
	p {
		a {
			color: $m1;
			&:hover {
				text-decoration: underline;
				color: #fff;
			}
		}
	}
	.more-links a {
		color: #fff;
		&:after {
			color: $m1;
		}
		&:hover {
			color: $m1;
		}
	}
}

.inline-img {
	@mixin media $medium {
		max-width: em(280);
	}
	&--right {
		@mixin media $large {
			float: right;
			padding-left: em(20);
		}
	}
	&--left {
		@mixin media $large {
			float: left;
			padding-right: em(20);
		}
	}
}

.callout-panel {
	@mixin background-image-properties;
	border-top: 4px solid $m3;
	margin-bottom: em(30);

	&--content {
		max-width: 900px;
		margin: 0 auto;
		padding: em(80) em(20);
		min-height: em(315);
		text-align: center;
		@mixin media $large {
			padding: em(130) em(20);
		}
		p {
			margin-bottom: 0;
			color: #fff;
			font-size: em(22);
			font-weight: 600;
			line-height: 1.3;
			@mixin media $large {
				font-size: em(40);
			}
		}
	}
}

.two-col-content {
	margin-bottom: em(30);
	@mixin media $large {
		margin-left: -15px;
		margin-right: -15px;
	}
	.col-large-6 {
		@mixin media $large {
			padding-left: 15px;
			padding-right: 15px;
		}
		img {
			margin-bottom: em(30);
			@mixin media $large {
				margin-bottom: 0;
			}
		}
		.h5, .h4 {
			margin-top: 0;
		}
	}
}

.four-square-grid {
	margin-bottom: em(50);

	.col-large-6 {
		@mixin media $large {
			height: em(500);
		}
	}

	.img-grid {
		height: em(300);
		@mixin background-image-properties;
		@mixin media $large {
			height: em(500)
		}
	}
	.content-grid {
		padding: em(30);
		background: #f1f1f1;
		@mixin media $large {
			padding: em(40);
		}
		@mixin media $yatterman {
			padding: em(80);
		}
	}
	.right-grid {
		float: right;
	}
	.left-grid {
		float: left;
	}
}

.fourohfour {
	padding-top: 30px;
	padding-bottom: 40px;
}

.card-content a {
	text-decoration: none;
}

.block-content a {
	text-decoration: none;
}

#site-map {
	@mixin compartment;
}
