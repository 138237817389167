/* ========================================
// Instagram
// ===================================== */

.instagram-feed {
  @mixin list-reset;
}

  .instagram-item {
    position: relative;
    display: block;
  }

    .instagram-item__image {
      display: block;
      max-width: 100%;
    }

    .instagram-item__details {}

      .instagram-item__caption {}


/* ========================================
// Twitter
// ===================================== */

.twitter-feed {
  @mixin list-reset;
}

  .twitter-item {}
