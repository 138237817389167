/* ========================================
// Site Footer
// ===================================== */

.site__footer {
  position: relative;
  overflow: hidden;
  background: $s1;
  clear: both;

  * {
    color: white;
  }

  .nav-social {
    margin: 0;
  }
}

  .lace {
    .site__footer & {
      @mixin media $large {
        padding: em(20) em(30);

        [class^="col-"] {
          margin: 0;
        }
      }
    }
  }

  .sole {}


  .umd-footer {

    background: $m3;
    padding: 1em 0;

    * {
      font-size: 12px;

      @mixin media $wooser {
        font-size: 16px;
      }

    }

    [class^="col-"] {
      margin: 0;
    }

    .compartment {
      padding: 1em 0;
    }

  }

.nav-footer {

  ul {

    }

  li {
    border-bottom: 2px solid $s2;
  }

  a {
    text-transform: uppercase;
    display: block;
    padding: 1em;
    font-weight: bold;
    font-size: em(16);
    &:hover {
      color: #fff;
    }
  }


  @mixin media $large {
    ul {
      column-count: 2;
    }

    li {
      border: none;
      padding: 0.25em;

    }

    a {
      padding: 0;

    }
  }

  @mixin media $gamagori {
    margin-top: em(15);

    ul {
      column-count: 1;
    }

    li {
      display: inline-block;

      &:last-child {
        a {
          border: none;
          &:after {
            display: none;
          }
        }
       }
    }
    a {
      padding: 0 em(0);
      padding-left: 5px;
      &:after {
        content: '|';
        margin-left: 11px;
        color: $m1;
        display: inline-block;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }

}

.nav-footer {
  text-align: center;
      margin-top: 0;
  @mixin media $wooser {
    text-align: left;
  }
}

.nav-legal {

  li:last-child {
    a {
      border: none;
    }
  }
  a {
    padding: 0 em(10);
    border-right: 1px solid white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: #fff;
    }
  }
}

.nav-footer li a.active {
  color: #fff;
}
