/* ========================================
// Social Navigation
// ===================================== */

.nav-social {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  @mixin media $wooser {
    padding-top: 0;
  }

  ul {
    text-align: center;
    margin-bottom: 0;
    padding-left: 0;
    @mixin media $large {
      text-align: right;
    }
  }

    li {
      display: inline-block;
      padding: 0.2em;
    }

      a {
        height: em(40);
        width: em(40);
        border-radius: 100%;
        background: $m1;
        display: block;
        position: relative;
        text-align: center;
        transition: 0.5s;
        color: #000;
        line-height: 2.3;
        &:hover { 
          transform: scale(1.2);
        }

        i {
          color: #000;
        }

      }

}
