.quick-nav {
    padding: 1em;

    column-count: 2;
    text-transform: uppercase;
    font-weight: bold;
    li {

    }
    a {
        padding: 0.75em 0;
        color: $s6;
        font-size: em(14);
    }

    border-bottom: 2px solid #292F35;
}