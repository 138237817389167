@define-mixin font-face $name, $path, $style: normal, $weight: normal {

  @font-face {
    font-family: "$name";
    font-style: $style;
    font-weight: $weight;
    src: url("../fonts/$path.eot") format("eot"),
         url("../fonts/$path.woff") format("woff"),
         url("../fonts/$path.ttf") format("truetype"),
         url("../fonts/$path.svg#$path") format("svg");
  }

}


@define-mixin fa {
	display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}