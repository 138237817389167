/* ========================================
// Unique Navigation
// ===================================== */

.nav--mobile-only {
  @mixin media $silkDrawerDisappears {
    display: none;
  }
}

.utility-block {
  background: url(../images/dots.png) black;
  z-index: 0;
  position: relative;
  .flex {
      flex-direction: row-reverse;
  }
  .col-big-5 {
  	z-index: 200;
  }
  .utility-wrap {
  	overflow: hidden;
  }
}


.utility-nav {
	&:first-of-type {
		.utility-nav--title {
			@mixin media $large {
				border-bottom: 3px solid #000;
			}
		}
	}
	.nav-left {
		background: #000;
		position: relative;
		a {
			color: #fff;
			&:hover {
				color: $m1;
			}
		}
	}
	.nav-right {
		background: #999;
		a {
			color: #fff;
			text-decoration: none;
		}
	}
	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
		li {
			margin: 0;
			padding: 0;
		}
	}
	.utility-nav--menu-full {
		display: none;
		background: #333;
	}
	&.active {
		.utility-nav--menu-full {
			display: block;
		}
/*		.utility-nav--title {
			background: $m2;
		}*/
		.plus-minus {
			background: $m2;
			&:after {
				transform: rotate(180deg);
			}
			&:before {
				transform: rotate(90deg);
				opacity: 0;
			}
		}
	}
	.utility-nav--title {
		margin: 0;
		padding: em(20) em(60) em(20) em(20);
		cursor: pointer;
		text-transform: uppercase;
		font-size: em(18);
		text-align: center;
		position: relative;
		background: $m1;
		transition: 0.2s;
		@mixin media $large {
  		font-size: em(22);
  		padding: em(30) em(90) em(30) em(20);
  	}
  	&:hover {
  		text-decoration: underline;
  	}
		.plus-minus {
	    background-color: $m2;
	    width: 60px;
	    height: 100%;
	    position: absolute;
	    top: 0;
	    right: 0;
	    @mixin media $large {
    		width: 100px;
    	}
	    &:after {
	    	position: absolute;
	    	content: '';
	    	width: 15px;
	    	height: 3px;
	    	background: #000;
	    	top: 50%;
	    	left: 20px;
	    	transition: 0.2s;
	    	@mixin media $large {
	    		width: 30px;
	    		left: 35px;
	    	}
	    }
	    &:before {
	    	position: absolute;
	    	content: '';
	    	width: 15px;
	    	height: 3px;
	    	background: #000;
	    	top: 50%;
	    	left: 20px;
	    	transform: rotate(90deg);
	    	transition: 0.4s;
	    	@mixin media $large {
	    		width: 30px;
	    		left: 35px;
	    	}
	    }
		}
	}
	.main-utility {
		> li {
			&.active-sub {
				.sub-utility {
					z-index: 300;
					transform: translate3d(0, 0, 0);
					@mixin media $huge {
						opacity: 1;
						transform: translate3d(100%, 0, 0);
					}
				}
				> a {
					color: $m1;
					text-decoration: none;
					&:after {
						@mixin media $huge {
							@mixin fa;
							content: "\f00c";
							color: $m1;
							position: absolute;
							right: em(40);
							font-size: em(24);
						}
					}
					&:before {
						content: '';
						position: absolute;
						right: 0;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 15px 20px 15px 0;
						border-color: transparent #333 transparent transparent;
					}
				}
			}
			> a {
				padding: em(25) em(20);
				padding-left: em(60);
				display: inline-block;
				border-bottom: 1px solid #333;
				width: 100%;
				font-weight: 600;
				position: relative;
				text-decoration: none;
				&:hover {
					color: $m1;
					background-color: rgba(255,210,0,0.4);
				}
			}
			.fa-chevron-right {
				float: right;
		    padding-top: 5px;
		    color: $m1;
		    position: relative;
		    @mixin media $huge {
		    	display: none;
		    }
		    &:after {
		    	content: '';
		    	height: 79px;
		    	width: 1px;
		    	background-color: #333;
		    	position: absolute;
		    	left: -26px;
    			top: -25px;
		    }
			}
			&.active {
				> a {
					color: $m1;
				}
				.plus-minus {
					background: $m2;
					&:before {
						transform: rotate(180deg);
					}
					&:after {
						transform: rotate(90deg);
						opacity: 0;
					}
				}
			}
		}
		&--no-children {
      @mixin media $big {
			 column-count: 2;
    	 column-gap: 0;
      }
			a {
				padding: em(25) em(20);
				padding-left: em(60);
				display: inline-block;
				border-bottom: 1px solid #333;
				width: 100%;
				font-weight: 600;
				position: relative;
				text-decoration: none;
				&:hover {
					color: $m1; 
					background-color: rgba(255,210,0,0.4);
				}
			}
			li{
				border-right: 1px solid #333;
			}
		}
	}
	.sub-utility {
		position: absolute;
		width: calc(100%);
		top: 0;
		height: 100%;
		background: #333;
		transform: translate3d(100%, 0, 0);
		padding-top: em(15);
		transition: transform 0.3s;
		opacity: 1;
		z-index: 100;
		@mixin media $huge {
			opacity: 0;
			transition: opacity 0.1s;
			padding-top: em(30);
		}
		> li {
			> a {
				display: inline-block;
				width: 100%;
				padding: em(15);
				padding-left: 50px;
				font-weight: 600;
				text-decoration: none;
				&:hover {
					color: $m1;
				}
				&.back-trigger {
					text-transform: uppercase;
					color: $m1;
					i {
						padding-right: 10px;
					}
					@mixin media $large {
						display: none;
					}
				}
			}
		}
	}
}

@keyframes icon-bounce {
  0%, 100%   { transform: translate3d(8px, 0, 0); }
  50% { transform: translate3d(12px, 0, 0);  }
}
