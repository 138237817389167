@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,400,400i,600,700');

/* ========================================
// Headings
// ===================================== */

@define-mixin default-heading {
  margin-top: 0;
  font-family: $sansSerif;
  font-weight: normal;
}


/* 1:Heading One */

@define-mixin h1 {
  font-size: em($h2Size);
  line-height: nu($h2Line, $h2Size); 

  @mixin media $medium {
    font-size: em($h1Size);
    line-height: nu($h1Line, $h1Size);
  }

}

@define-mixin h1--xlarge {
  @mixin default-heading;
  font-size: em(40);
  line-height: 1.05;
  font-weight: 300;
  color: #4A484C;
  margin-top: em(5);

  @mixin media $medium {
    font-size: em(54);
  }

}

h1,
.h1 {
  @mixin h1;
}


.h1--xlarge {
  @mixin h1--xlarge;
}

/* 2:Heading Two */

@define-mixin h2 {
  font-size: em($h3Size);
  line-height: nu($h3Line, $h3Size);

  @mixin media $medium {
    font-size: em($h2Size);
    line-height: nu($h2Line, $h2Size);
  }

}

h2,
.h2 {
  @mixin h2;
}


/* 3:Heading Three */

@define-mixin h3 {
  font-size: em($h4Size);
  line-height: nu($h4Line, $h4Size);

  @mixin media $medium {
    font-size: em($h3Size);
    line-height: nu($h3Line, $h3Size);
  }

}


h3,
.h3 {
  @mixin h3;
}


/* 4:Heading Four */

@define-mixin h4 {
  font-size: em($h5Size);
  line-height: nu($h5Line, $h5Size);

  @mixin media $medium {
    font-size: em($h4Size);
    line-height: nu($h4Line, $h4Size);
  }

}

h4,
.h4 {
  @mixin h4;
}


/* 5:Heading Five */

@define-mixin h5 {
  font-size: em($h6Size);
  line-height: nu($h6Line, $h6Size);
  @mixin media $medium {
    font-size: em($h5Size);
    line-height: nu($h5Line, $h5Size);
  }

}

h5,
.h5 {
  @mixin h5;
}


/* 6:Heading Six */

@define-mixin h6 {
  font-size: em($h6Size);
  line-height: nu($h6Line, $h6Size);
}

h6,
.h6 {
  @mixin h6;
}


/**
 Text Alignment
 */
.text{
  &-left {
  text-align: left;
   }

  &-center {
    text-align: center
  }

 &-right {
  text-align: right;
  }
}


