.news-header-wrap {
	background-color: #121B21;
	color: $m1;
}

.news--list {
	padding-bottom: em(40);
}

.news {
	&--header {
		padding-top: 20px;
	}

	&--breadcrumbs {
		@mixin list-reset;
		li {
			display: inline-block;
			a {
				color: #000;
				font-size: em(14);
				padding-right: em(10);
				&:after {
					content: '>';
					margin-left: em(10);
				}
			}
			&:last-of-type {
				a:after {
					display: none;
				}
			}
		}
	}

	&--tagline {
		border-bottom: 1px solid $s7;
		padding-bottom: em(20);
		.button {
			@mixin media 600px {
				float: right;
			}
		}
	}

	&--form {
		padding-top: em(30);
		padding-bottom: em(30);
		.input-select {
			margin-bottom: em(20);
			@mixin media $big {
				width: 47.5%;
				margin-right: 5%;
				margin-bottom: 0;
				float: left;
			}
		}
		.input-calendar {
			color: #333;
			margin-bottom: em(20);
			@mixin media $big {
				display: inline-block;
				width: 47.5%;
				margin-bottom: 0;
			}
		}
		.input-search {
			@mixin media $large {
				width: 95%;
				margin-left: 5%;
			}
		}
		.keyword-title {
			margin-top: 27px;
			font-weight: normal;
			font-size: em(18);
			margin-left: 0;
			@mixin media 800px {
				margin-left: 5%;
			}
		}
	}

	&--grid {
		margin-bottom: em(100);
		.news--grid--row {
			overflow: hidden;
			@mixin media $large {
				margin-bottom: 30px;
			}
		}
		.card {
			margin-bottom: 30px;
			@mixing media $large {
				margin-bottom: 0;
			}
			.card-title {
				&:hover {
					text-decoration: underline;
				}
			}
		}
		.grid-left {
			float: left;
			@mixin media $wooser {
				padding-right: 15px;
				height: 710px;
			}
		}
		.grid-right {
			float: right;
			@mixin media $wooser {
				padding-left: 15px;
				height: 710px;
			}
		}
		.row-top,
		.row-bottom {
			height: 340px;
		}
		.row-top {
			margin-bottom: 30px;
		}
		.row-bottom {
			margin-bottom: 30px;
			@mixin media $large {
				margin-bottom: 0;
			}
		}
		.block--news,
		.block--quote {
			height: 340px;
			margin-bottom: 0;
		}
		.block--news {
			.news-title {
				color: #fff;
			}
			&:hover {
				.news-title {
					text-decoration: underline;
				}
			}
		}
		.block-first {
			margin-bottom: 30px;
			@mixin media $big {
				padding-right: 15px;
				margin-bottom: 0;
			}
		}
		.block-last {
			margin-bottom: 30px;
			@mixin media $big {
				padding-left: 15px;
				margin-bottom: 0;
			}
		}
	}

	&--footer {
		background: #f1f1f1;
		text-align: center;
		padding: em(20);
		.section-title {
			@mixin media $big {
				padding-right: em(30);
			}
		}
	}
}

.search-news-form {
	color: #fff;
	.input-search {
		margin-left: 0;
		width: 100%;
		margin-bottom: em(30);
		@mixin media $big {
			width: 70%;
		}
	}
}

#search-results {
	margin-bottom: em(100);
	padding-top: em(30);
}

.news--form select {
	color: #aaa;
}

.events .news--form {
    color: #fff;
}

.events .news--form .keyword-title {
	display: none;
	@mixin media $large {
		visibility: hidden;
		display: block;
	}
}

.events .updated-text {
	margin-top: 20px;
}

.events .article-keyword {
	color: $m1;
}

.events .calendar-grid table tr:nth-of-type(even) {
    display: none;
}

.home .news--grid {
	margin-bottom: 0;
}

.news--list .col-large-4 {
	@mixin media $large {
		width: 373px;
	}
}

.clear-filters {
	color: #f0f0f0;
	display: inline-block;
	margin-left: 10px;
	&:hover {
		color: $m1;
	}
}
